import { ExperienceSortTypes, fetchExperienceSearchList } from 'actions/ExperienceActions'
import { EmptyExperienceList } from 'constants/experience'
import useMountedEffect from 'hooks/useMountedEffect'
import arrayShuffle from 'lib/array/arrayShuffle'
import { without } from 'lib/array/arrayUtils'
import { getExperienceListKey } from 'lib/experiences/experienceUtils'
import memoize from 'lib/memoize/memoize'
import { useEffect, useMemo, useState } from 'react'

import { useAppDispatch, useAppSelector } from 'hooks/reduxHooks'

/**
 * Memoize is used here as it provides a stable set of offer ids for that exact version of the array
 * This ensures that we always return the same randomised set throughout the life of the page
 */
const getRandomizedOfferIds = memoize((offerIds: Array<string>) => arrayShuffle(offerIds))

interface Props {
  categoryCodes?: Array<number>;
  placeId?: string;
  fallbackPlaceIds?: Array<string>;
  placeIdToIgnore?: string;
  sortBy?: ExperienceSortTypes;
  excludeIds?: Array<string>;
  campaigns?: Array<string>;
  priceLte?: number;
  priceGte?: number;
  randomize?: boolean;
  enabled?: boolean;
  bounds?: string;
}
const EmptyArray = []
function useExperienceSearchList(props: Props): App.ApiCallState<{ ids: Array<string>, placeId: string }> {
  const {
    categoryCodes,
    placeId,
    fallbackPlaceIds = EmptyArray,
    placeIdToIgnore,
    sortBy,
    priceLte,
    priceGte,
    excludeIds = EmptyArray,
    campaigns,
    randomize,
    bounds,
    enabled = true,
  } = props
  const dispatch = useAppDispatch()
  const [currentPlaceId, setPlaceId] = useState(placeId)

  useMountedEffect(() => {
    // if the place ID changes, set it and restart
    setPlaceId(placeId)
  }, [placeId])

  const listKey = useMemo(() => {
    return getExperienceListKey({ placeId: currentPlaceId, categoryCodes, sortBy, priceLte, priceGte, campaigns, bounds })
  }, [currentPlaceId, categoryCodes, sortBy, priceLte, priceGte, campaigns, bounds])

  const experienceList = useAppSelector((state) => state.experience.experienceLists[listKey] ?? EmptyExperienceList)

  useEffect(() => {
    if ((currentPlaceId || bounds) && enabled) {
      dispatch(fetchExperienceSearchList(currentPlaceId, { placeIdToIgnore, categoryCodes, sortBy, priceLte, priceGte, campaigns, bounds }))
    }
  }, [currentPlaceId, placeIdToIgnore, categoryCodes, sortBy, priceLte, priceGte, campaigns, bounds, enabled, dispatch])

  useEffect(() => {
    if (fallbackPlaceIds.length > 0) {
      if (!experienceList.fetching && experienceList.ids.length === 0) {
        // couldn't find anything...move onto our fallbacks (or the next fallback)
        const existingFallbackIndex = fallbackPlaceIds.findIndex(id => id === currentPlaceId)

        const nextPlaceId = fallbackPlaceIds[existingFallbackIndex + 1]
        if (nextPlaceId) {
          setPlaceId(nextPlaceId)
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experienceList, fallbackPlaceIds])

  const list = useMemo(() => {
    if (!randomize && excludeIds.length === 0) {
      return {
        ...experienceList,
        placeId: currentPlaceId,
      }
    }

    let ids = experienceList.ids
    if (randomize) {
      ids = getRandomizedOfferIds(experienceList.ids)
    }

    return {
      ...experienceList,
      ids: excludeIds.length > 0 ? without(ids, ...excludeIds) : ids,
      placeId: currentPlaceId,
    }
  }, [randomize, excludeIds, experienceList, currentPlaceId])

  return list
}

export default useExperienceSearchList
