import moment from 'moment'

import { calculateNights } from '.'

import { pluralizeToString } from 'lib/string/pluralize'
import {
  SavedAccommodationRentalData,
  SavedHotelData,
} from 'tripPlanner/types/tripItem'

export function getHotelRoomDescription(
  unit: string = 'room',
  roomName?: string,
  rooms?: Array<App.Occupants>,
) {
  if (rooms && rooms.length > 0) {
    const text = `${[roomName, `(${pluralizeToString(unit, rooms.length)})`]
      .filter(Boolean)
      .join(' ')}`

    return roomName ? text : undefined
  }

  return roomName
}

export const getAccommodationPriceAndLabel = (
  offer: App.Offer | App.BedbankOffer,
  savedItemData: SavedHotelData | SavedAccommodationRentalData,
) => {
  const {
    checkIn,
    checkOut,
    price: savedPrice,
    value: savedValue,
  } = savedItemData
  const savedLuxPlusPrice =
    'luxPlusPrice' in savedItemData ? savedItemData.luxPlusPrice : undefined
  let price = savedPrice
  let value = savedValue
  let luxPlusPrice = savedLuxPlusPrice

  let priceLabel = 'From'

  if (offer.type !== 'bedbank_hotel' && offer.bundledWithFlightsOnly) {
    if (offer.lowestPricePackage) {
      const basePrice = offer.lowestPricePackage.price
      const baseLuxPlusPrice = offer.lowestPricePackage.memberPrice
      const propertyFees = offer.lowestPricePackage.propertyFees
      priceLabel = `${pluralizeToString(
        'night',
        offer.lowestPricePackage.duration,
      )} from`
      price = basePrice + propertyFees
      luxPlusPrice = baseLuxPlusPrice + propertyFees
    }

    if (offer.bundledWithFlightsOnly && savedItemData.flightBundle) {
      const savedFlightPrice = savedItemData.flightBundle.savedPrice

      if (
        savedItemData.flightBundle &&
        typeof price === 'number' &&
        typeof savedFlightPrice === 'number'
      ) {
        price += savedFlightPrice
        if (typeof luxPlusPrice === 'number') {
          luxPlusPrice += savedFlightPrice
        }
        if (typeof value === 'number') {
          value += savedFlightPrice
        }
      } else {
        // If `bundledWithFlightsOnly` is true, we *have* to show the price with flights for contractual reasons.
        // So if we don't have the flight price for whatever reason, then we just can't show the price here.
        price = undefined
        luxPlusPrice = undefined
        value = undefined
      }

      if (checkIn && checkOut) {
        const nights = calculateNights(moment(checkIn), moment(checkOut))
        if (nights) {
          priceLabel = `${pluralizeToString('night', nights)} + flights from`
        }
      }
    }
  }

  return { price, luxPlusPrice, priceLabel, value }
}
